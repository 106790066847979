// 
// card.scss
//

.card, .card-box {
    margin-bottom: $grid-gutter-width;
    border: 1px solid darken($border-color, 2%);;
    .card-drop {
        font-size: 20px;
        line-height: 0;
        color: $gray-500;
    }
}

.card-widgets {
    float: right;
    height: 16px;
    > a {
        color: inherit;
        font-size: 16px;
        display: inline-block;
        line-height: 1;
        margin-left: 7px;

        &.collapsed {
            i {
                &:before {
                    content: "\F415";
                }
            }
        }
    }
}


// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
}

.card-header{
    border: none;
}

.card-title{
    font-size: 16px;
}

.header-title {
    font-size: 16px;
    margin: 0 0 7px 0;
}

.sub-header {
    margin-bottom: $grid-gutter-width;
    color: $gray-500;
    font-size: 13px;
}


// card footer

.card-footer{
    border-top: 1px solid $gray-200;
}

// Custom card box
.card-box {
    background-color: $card-bg;
    padding: $card-spacer-x;
    box-shadow: $box-shadow;
    margin-bottom: $grid-gutter-width;
    @include border-radius($card-border-radius);
}

//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: rgba($light, 0.8);
    cursor: progress;

    .card-portlets-loader {
        background-color: $dark;
        animation: rotatebox 1.2s infinite ease-in-out;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: - ($grid-gutter-width / 2);
        margin-top: - ($grid-gutter-width / 2);
    }
}

@keyframes rotatebox {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

@media (min-width: 1024px) {  
  .card-columns {column-count: 4;}
}